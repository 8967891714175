/*!
Template :	XIOM
Author	 :	Softnio
Version	 :	1.0.0
Updated	 :	18.08.2018
*/
/*!
 * Table of Contents
 * 01 - Fonts
 * 02 - Reset
 * 03 - Common
 * 04 - Utility
 * 05 - Header
 * 06 - Button
 * 07 - Elements
 */
/** IMPORTANT NOTE: DO NOT Edit this file.  Best to write own code in css/theme.css file.  */
/*!  01 - Fonts  !*/
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Bold.eot');
  src: local('Poppins Bold'), local('Poppins-Bold'),
    url('../fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Poppins-Bold.woff2') format('woff2'),
    url('../fonts/Poppins-Bold.woff') format('woff'),
    url('../fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-SemiBold.eot');
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
    url('../fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('../fonts/Poppins-SemiBold.woff') format('woff'),
    url('../fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Regular.eot');
  src: local('Poppins Regular'), local('Poppins-Regular'),
    url('../fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Poppins-Regular.woff2') format('woff2'),
    url('../fonts/Poppins-Regular.woff') format('woff'),
    url('../fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Light.eot');
  src: local('Poppins Light'), local('Poppins-Light'),
    url('../fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Poppins-Light.woff2') format('woff2'),
    url('../fonts/Poppins-Light.woff') format('woff'),
    url('../fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Regular.eot');
  src: local('Lato Regular'), local('Lato-Regular'),
    url('../fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Lato-Regular.woff2') format('woff2'),
    url('../fonts/Lato-Regular.woff') format('woff'),
    url('../fonts/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Hind Madurai';
  src: url('../fonts/HindMadurai-Regular.eot');
  src: local('Hind Madurai Regular'), local('HindMadurai-Regular'),
    url('../fonts/HindMadurai-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HindMadurai-Regular.woff2') format('woff2'),
    url('../fonts/HindMadurai-Regular.woff') format('woff'),
    url('../fonts/HindMadurai-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Hind Madurai';
  src: url('../fonts/HindMadurai-SemiBold.eot');
  src: local('Hind Madurai SemiBold'), local('HindMadurai-SemiBold'),
    url('../fonts/HindMadurai-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HindMadurai-SemiBold.woff2') format('woff2'),
    url('../fonts/HindMadurai-SemiBold.woff') format('woff'),
    url('../fonts/HindMadurai-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Hind Madurai';
  src: url('../fonts/HindMadurai-Bold.eot');
  src: local('Hind Madurai Bold'), local('HindMadurai-Bold'),
    url('../fonts/HindMadurai-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HindMadurai-Bold.woff2') format('woff2'),
    url('../fonts/HindMadurai-Bold.woff') format('woff'),
    url('../fonts/HindMadurai-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
/*!  02 - Reset !*/
body {
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 1.875;
  font-weight: normal;
  color: #6f7c8a;
  background-color: #f8fbff;
  position: relative;
  min-width: 320px;
}

@media all and (min-width: 576px) {
  body {
    font-size: 15px;
  }
}
@media all and (min-width: 1400px) {
  body {
    font-size: 16px;
  }
}
html,
body {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: 1.33;
  font-weight: 600;
}

h1,
.h1 {
  font-size: 2.93em;
}

h2,
.h2 {
  font-size: 2.3em;
}

h3,
.h3 {
  font-size: 1.87em;
}

h4,
.h4 {
  font-size: 1.6em;
}

h5,
.h5 {
  font-size: 1.33em;
}

h6,
.h6 {
  font-size: 1.07em;
}

a {
  color: #6480ce;
}

.lead {
  font-size: 1.1em;
  line-height: 1.8;
  font-weight: normal;
}

@media all and (min-width: 576px) {
  .lead {
    font-size: 1.2em;
  }
}
ul,
ol {
  padding: 0;
}
ul li,
ol li {
  list-style: none;
}

.relative {
  position: relative;
}

b,
strong {
  font-weight: 600;
}

a {
  outline: 0;
  transition: all 0.5s;
}
a:link,
a:visited {
  text-decoration: none;
}
a:hover,
a:focus,
a:active {
  outline: 0;
}

button {
  cursor: pointer;
  transition: all 0.5s;
}
button:focus {
  outline: 0;
}

.container {
  position: relative;
  z-index: 5;
}

.overflow {
  overflow: hidden;
}

/*!  03 - Common css  !*/
.aster {
  font-family: 'Lato';
}
.aster h1,
.aster h2,
.aster h3,
.aster h4,
.aster h5,
.aster h6 {
  font-family: 'Poppins';
  color: #3d486a;
}
.aster blockquote {
  color: #6f7c8a;
}
.aster-alter {
  background-color: #fff;
}

.hosta {
  background-color: #fff;
}
.hosta h1,
.hosta h2,
.hosta h3,
.hosta h4,
.hosta h5,
.hosta h6,
.hosta span,
.hosta blockquote {
  font-family: 'Hind Madurai';
  color: #4a5b7a;
}
.hosta p {
  font-weight: 300;
}
.hosta .lead {
  font-weight: normal;
}

a {
  text-decoration: none;
}
a:hover {
  color: #fff;
  text-decoration: none;
}

img {
  max-width: 100%;
}

input:focus {
  outline: none;
}

textarea {
  height: 60px;
}

textarea:focus {
  outline: none;
}

@media all and (min-width: 992px) {
  .sec-text-pad {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.sec-heading {
  padding-bottom: 30px;
}
.sec-heading p {
  padding-top: 20px;
}

.sec-heading-s3 {
  padding-bottom: 20px;
}
.sec-heading-s3 p {
  padding-top: 10px;
  color: #4a5b7a;
}

@media all and (min-width: 576px) {
  .sec-heading {
    padding-bottom: 50px;
  }
  .sec-heading p {
    padding-top: 50px;
  }
  .sec-heading-s3 {
    padding-bottom: 50px;
  }
  .sec-heading-s3 p {
    padding-top: 20px;
  }
  .sec-heading-xl p {
    padding-top: 30px;
  }
}
.sec-heading h2::before {
  content: '';
  position: absolute;
  width: 100px;
  height: 40px;
  border-radius: 40px;
  background-color: #f9dfea;
  -webkit-transform: rotate(-30deg);
  -moz-transform: rotate(-30deg);
  left: 36%;
  top: -4%;
  z-index: -1;
}

@media all and (min-width: 361px) {
  .sec-heading h2::before {
    left: 38%;
  }
}
@media all and (min-width: 481px) {
  .sec-heading h2::before {
    width: 120px;
    height: 50px;
  }
}
@media all and (min-width: 768px) {
  .sec-heading h2::before {
    width: 140px;
    left: 40%;
  }
}
@media all and (min-width: 992px) {
  .sec-heading h2::before {
    width: 150px;
    height: 60px;
    left: 37%;
  }
}
.text-block {
  padding-top: 30px;
}
.text-block p {
  padding: 0;
  margin: 0;
}

@media all and (min-width: 768px) {
  .text-block {
    padding-top: 0;
  }
  .text-block p {
    padding: 10px 0;
  }
}
.text-block-1 h2 {
  font-weight: 600;
  font-size: 1.5em;
  line-height: 1.5;
}

@media all and (min-width: 480px) {
  .text-block-1 p {
    padding-top: 10px;
  }
  .text-block-1 h2 {
    font-size: 2.5em;
  }
}
@media all and (min-width: 992px) {
  .text-block-1 p {
    padding-right: 80px;
  }
  .text-block-1 h2 {
    font-size: 2.88em;
  }
}
.text-block-2 {
  padding-top: 30px;
}
.text-block-2 p {
  padding-top: 5px;
  padding-bottom: 10px;
  margin: 0;
}

@media all and (min-width: 768px) {
  .text-block-2 {
    padding-top: 0;
  }
  .text-block-2 p {
    padding-top: 20px;
    padding-bottom: 30px;
  }
}
.sec-img {
  max-width: 250px;
  margin: 0 auto;
  text-align: center;
}

@media all and (min-width: 576px) {
  .sec-img {
    max-width: 450px;
  }
}
@media all and (min-width: 992px) {
  .sec-img {
    max-width: 100%;
  }
}
.sec-title-sm {
  font-weight: normal;
  font-size: 1em;
  line-height: 1.8;
}
.sec-title-md {
  line-height: 1.5;
}
.sec-title-lg {
  font-size: 1.4em;
  line-height: 1.5;
}
.sec-title-xl {
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1.375;
}
.sec-title-xxl {
  font-size: 1.6em;
  line-height: 1.5;
}

@media all and (min-width: 480px) {
  .sec-title-lg {
    font-size: 2.2em;
  }
  .sec-title-xl {
    font-size: 2.5em;
  }
  .sec-title-xxl {
    font-size: 2.5em;
  }
}
@media all and (min-width: 768px) {
  .sec-title {
    font-size: 3em;
  }
  .sec-title-sm {
    font-size: 1.25em;
  }
  .sec-title-lg {
    font-size: 2.25em;
  }
  .sec-title-xl {
    font-size: 2.7em;
  }
}
.pt {
  padding-top: 50px;
}
.pt-sm {
  padding-top: 20px;
}

.pb {
  padding-bottom: 50px;
}

@media all and (min-width: 576px) {
  .pt {
    padding-top: 100px;
  }
  .pb {
    padding-bottom: 100px;
  }
}
@media all and (min-width: 992px) {
  .pt {
    padding-top: 130px;
  }
  .pt-sm {
    padding-top: 50px;
  }
  .pb {
    padding-bottom: 130px;
  }
}
.horizontal-line {
  height: 1px;
  width: 100px;
  background-color: #fff;
  margin-bottom: 5px;
  margin-left: 2px;
  display: inline-block;
}

.error {
  color: #d63031;
}

/*!  04 - Utility !*/
.section-pad {
  padding-top: 50px;
  padding-bottom: 50px;
}
.section-pad-xs {
  padding-top: 50px;
  padding-bottom: 50px;
}
.section-pad-sm {
  padding-top: 50px;
  padding-bottom: 50px;
}
.section-pad-md {
  padding-top: 50px;
  padding-bottom: 50px;
}
.section-pad-lg {
  padding-top: 60px;
  padding-bottom: 60px;
}
.section-pad-xl {
  padding-top: 70px;
  padding-bottom: 70px;
}
.section-pad.nopd {
  padding-top: 0;
  padding-bottom: 0;
}
.section-pad.nopb {
  padding-bottom: 0;
}
.section-pad.nopt {
  padding-top: 0;
}

@media all and (min-width: 576px) {
  .section-pad {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .section-pad-xs {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .section-pad-sm {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .section-pad-md {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .section-pad-lg {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .section-pad-xl {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media all and (min-width: 992px) {
  .section-pad {
    padding-top: 175px;
    padding-bottom: 175px;
  }
  .section-pad-xs {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .section-pad-sm {
    padding-top: 0;
    padding-bottom: 110px;
  }
  .section-pad-md {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .section-pad-lg {
    padding-top: 190px;
    padding-bottom: 190px;
  }
  .section-pad-xl {
    padding-top: 245px;
    padding-bottom: 245px;
  }
}
@media all and (min-width: 992px) {
  .box-border {
    border: 1px solid #e1e4ec;
  }
  .box-border-tn {
    border-top: none;
  }
  .box-border-bn {
    border-bottom: none;
  }
  .box-border-rn {
    border-right: none;
  }
  .box-border-ln {
    border-left: none;
  }
}
.mb-x1 {
  margin-bottom: 10px;
}
.mb-x2 {
  margin-bottom: 20px;
}
.mb-x3 {
  margin-bottom: 30px;
}
.mb-x4 {
  margin-bottom: 40px;
}
.mb-x5 {
  margin-bottom: 50px;
}

.mbn-x1 {
  margin-bottom: -10px;
}
.mbn-x2 {
  margin-bottom: -20px;
}
.mbn-x3 {
  margin-bottom: -30px;
}
.mbn-x4 {
  margin-bottom: -40px;
}
.mbn-x5 {
  margin-bottom: -50px;
}

.mt-x1 {
  margin-top: 10px;
}
.mt-x2 {
  margin-top: 20px;
}
.mt-x3 {
  margin-top: 30px;
}
.mt-x4 {
  margin-top: 40px;
}
.mt-x5 {
  margin-top: 50px;
}

.mtn-x1 {
  margin-top: -10px;
}
.mtn-x2 {
  margin-top: -20px;
}
.mtn-x3 {
  margin-top: -30px;
}
.mtn-x4 {
  margin-top: -40px;
}
.mtn-x5 {
  margin-top: -50px;
}

/*!  05 - Header  !*/
.navbar-brand {
  transition: 0.6s;
  padding: 0;
  height: auto;
  transition: all 0.5s linear;
}
.navbar-brand img {
  max-height: 100%;
}

.site-header {
  position: relative;
  transition: all 0.5s linear;
}
.site-header .navbar {
  background: transparent;
  position: relative;
  z-index: 2000;
  top: 0;
  width: 100%;
  transition: all 0.5s linear;
}
.site-header.has-fixed .navbar {
  position: fixed;
  width: 100%;
  transition: padding 0.5s linear;
  padding: 10px 0;
  top: 0;
  background-color: #dee4ff;
}
.site-header.has-fixed .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  /* height: 50px; */
  transition: all 0.5s linear;
}

.header-area-s2 .site-header {
  position: relative;
  transition: all 0.5s linear;
}
.header-area-s2 .site-header .navbar {
  background: transparent;
  position: relative;
  z-index: 2000;
  top: 0;
  width: 100%;
  transition: all 0.5s linear;
}
.header-area-s2 .site-header.has-fixed .navbar {
  position: fixed;
  width: 100%;
  transition: padding 0.5s linear;
  padding: 10px 0;
  top: 0;
  background-color: #8b9aff;
}
.header-area-s2 .site-header.has-fixed .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  height: 50px;
  transition: all 0.5s linear;
}

.header-area-s3 .site-header {
  position: relative;
  transition: all 0.5s linear;
}
.header-area-s3 .site-header .navbar {
  background: transparent;
  position: relative;
  z-index: 2000;
  top: 0;
  width: 100%;
  transition: all 0.5s linear;
}
.header-area-s3 .site-header.has-fixed .navbar {
  position: fixed;
  width: 100%;
  transition: padding 0.5s linear;
  padding: 10px 0;
  top: 0;
  background-color: #4e3bb1;
}
.header-area-s3 .site-header.has-fixed .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  height: 50px;
  transition: all 0.5s linear;
}

@media all and (min-width: 992px) {
  .navbar-brand {
    height: 70px;
  }
  .site-header .navbar {
    position: absolute;
    padding: 15px 0;
  }
  .header-area-s2 .site-header .navbar {
    position: absolute;
    padding: 15px 0;
  }
  .header-area-s3 .site-header .navbar {
    position: absolute;
    padding: 15px 0;
  }
}
.navbar.is-transparent.active {
  background: #dee4ff;
}

.header-area-s2 .navbar.is-transparent.active {
  background: #8b9aff;
}

.header-area-s3 .navbar.is-transparent.active {
  background: #1a0c52;
}

@media all and (min-width: 992px) {
  .navbar.is-transparent.active {
    background: transparent;
  }
  .header-area-s2 .navbar.is-transparent.active {
    background: transparent;
  }
  .header-area-s3 .navbar.is-transparent.active {
    background: transparent;
  }
}
.navbar-toggler {
  background-color: #8b9aff;
}
.navbar-toggler:focus {
  outline: none;
}

.header-area-s2 .navbar-toggler {
  background-color: #3742fa;
}

.header-area-s3 .navbar-toggler {
  background-color: #6580ce;
}

.navbar-icon i {
  color: #fff;
}

.navbar-light .navbar-nav .nav-link {
  color: #606985;
  font-size: 0.875em;
  line-height: 2.5;
  font-family: 'Poppins';
  font-weight: normal;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #2980b9;
}

.header-area-s2 .navbar-light .navbar-nav .nav-link {
  color: #fff;
}
.header-area-s2 .navbar-light .navbar-nav .nav-link:hover {
  color: #3742fa;
}

.header-area-s3 .navbar-light .navbar-nav .nav-link {
  color: #fff;
}
.header-area-s3 .navbar-light .navbar-nav .nav-link:hover {
  color: #fff200;
}

.dropdown:hover .nav-link,
.dropdown:focus .nav-link {
  color: #2980b9;
}

.header-area-s2 .dropdown:hover .nav-link,
.header-area-s2 .dropdown:focus .nav-link {
  color: #3742fa;
}

.header-area-s3 .dropdown:hover .nav-link,
.header-area-s3 .dropdown:focus .nav-link {
  color: #fff200;
}

.nav-item {
  padding: 0 5px 0 5px;
}
.nav-item:last-child {
  padding-right: 0;
  padding-left: 5px;
  padding-top: 0;
}

@media all and (min-width: 992px) {
  .nav-item:last-child {
    padding-right: 0;
    padding-left: 18px;
    padding-top: 5px;
  }
}
@media all and (min-width: 1200px) {
  .nav-item {
    padding: 0 18px 0 18px;
  }
}

.logo {
  width: 160px;
  height: auto;
}

@media all and (min-width: 480px) {
  .logo {
    max-width: 45%;
  }
}
.dropdown-menu {
  background-color: #6580ce;
  padding: 0;
  top: 115%;
  border: none;
  border-radius: 0;
}
.dropdown-item {
  color: #fff;
  font-size: 0.875em;
  line-height: 2.5;
  font-family: 'Poppins';
  font-weight: normal;
  padding: 5px 15px;
  text-align: left;
}
.dropdown-item:hover {
  background-color: #4834d4;
  color: #fff;
}
.dropdown-item:focus {
  background-color: #6580ce;
  color: #fff;
}
.dropdown-item.active {
  background-color: #4834d4;
}
.dropdown-toggle:after {
  vertical-align: 1px;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
}

/*!  06 - Button  !*/
.btn-menu {
  display: inline-block;
  border: none;
  padding: 2px 30px 2px 30px;
  border-radius: 30px;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 0.875em;
  line-height: 2.5;
  background-color: #c8cfe7;
  color: #3d486a;
}
.btn-menu:hover {
  background-color: #ff5e8d;
}
.btn-menu-2 {
  border: 2px solid #fff;
  background-color: transparent;
  color: #fff;
}
.btn-menu-2:hover {
  background-color: transparent;
  border: 2px solid #ff5e8d;
}
.btn-menu-3 {
  font-family: 'Hind Madurai';
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  transition: all 0.5s ease;
}
.btn-menu-3 i {
  transition: all 0.5s ease;
  color: #f7949c;
  padding-left: 10px;
}
.btn-menu-3:hover {
  background-color: #c8cfe7;
  color: #3d486a;
}
.btn-menu-3:hover i {
  color: #3d486a;
}
.btn-link {
  text-decoration: none;
  color: #ff5e8d;
  font-family: 'Lato';
  font-weight: normal;
  font-size: 1em;
  line-height: 2.25em;
  border-bottom: 1px solid #ff5e8d;
}
.btn-link:focus {
  color: #ff5e8d;
  border: none;
}
.btn-link:hover {
  color: #7c98e7;
  border-bottom: 1px solid #7c98e7;
  text-decoration: none;
}
.btn-hover:hover {
  color: #ff5e8d;
  background-color: #fff;
  box-shadow: 0px 25px 35px rgba(255, 255, 255, 0.3);
}
.btn-lg:not([disabled]) {
  display: inline-block;
  border: none;
  border-radius: 30px;
  font-family: 'Poppins';
  font-weight: normal;
  padding: 5px 30px 5px 30px;
  color: #fff;
  box-shadow: 0px 25px 35px rgba(255, 94, 141, 0.3);
  font-size: 1em;
  line-height: 2;
  background-color: #ff5e8d;
}
.btn-lg:not([disabled]):hover {
  background-color: #7c98e7;
  box-shadow: 0px 25px 35px rgba(124, 152, 231, 0.3);
}

.btn-lg:disabled {
  display: inline-block;
  border: none;
  border-radius: 30px;
  font-family: 'Poppins';
  font-weight: normal;
  padding: 5px 30px 5px 30px;
  color: #fff;
  box-shadow: 0px 25px 35px rgba(139, 139, 139, 0.3);
  font-size: 1em;
  line-height: 2;
  background-color: #cbc9ca;
  cursor: not-allowed;
}

.btn-lg:disabled:hover {
  background-color: #cbc9ca;
  box-shadow: 0px 25px 35px rgba(139, 139, 139, 0.3);
}

.btn-lg-s3 {
  display: inline-block;
  font-family: 'Hind Madurai';
  font-weight: 600;
  position: relative;
  border: none;
  border-radius: 30px;
  padding: 5px 30px 5px 30px;
  background: linear-gradient(to right, #f5848f, #fdb8ab);
  -webkit-backface-visibility: hidden;
  z-index: 1;
}
.btn-lg-s3 span {
  color: #fff;
  position: relative;
  z-index: 3;
}
.btn-lg-s3:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background: linear-gradient(to right, #fdb8ab, #f5848f);
  transition: opacity 0.5s ease-out;
  z-index: 2;
  opacity: 0;
}
.btn-lg-s3:hover:after {
  opacity: 1;
}
.btn-s2:hover {
  box-shadow: none;
  background-color: #fff;
  color: #ff5e8d;
}
.btn-price {
  background-color: #c8cfe7;
  color: #3d486a;
  box-shadow: none;
}
.btn-price:hover {
  color: #fff;
  background-color: #ff5e8d;
  box-shadow: 0px 25px 35px rgba(255, 94, 141, 0.3);
}
.btn-xl {
  padding: 8px 50px 8px 50px;
}
.btn-xl-s3 {
  padding: 7px 45px 7px 45px;
}
.btn-md {
  padding: 8px 35px 8px 35px;
}

.active-box-s2 .btn-lg-s3 {
  background: linear-gradient(to right, #f5848f, #fdb8ab);
}
.active-box-s2 .btn-lg-s3:hover:after {
  opacity: 0;
}
.active-box-s2 span {
  color: #fff;
}

@media only screen and (min-width: 480px) {
  .btn-lg {
    padding: 7px 40px 7px 40px;
  }
  .btn-lg-s3 {
    padding: 7px 30px 7px 30px;
  }
  .btn-xl {
    padding: 8px 55px 8px 55px;
  }
  .btn-xl-s3 {
    padding: 7px 50px 7px 50px;
  }
  .btn-md {
    padding: 8px 40px 8px 40px;
  }
}
@media only screen and (min-width: 768px) {
  .btn-lg {
    padding: 7px 50px 7px 50px;
    font-size: 1.125em;
  }
  .btn-lg-s3 {
    padding: 7px 35px 7px 35px;
    font-size: 1.125em;
  }
  .btn-xl {
    padding: 8px 60px 8px 60px;
  }
  .btn-xl-s3 {
    padding: 7px 58px 7px 58px;
  }
  .btn-md {
    padding: 8px 50px 8px 50px;
  }
  .btn-link {
    font-size: 1.25em;
  }
}
/*!  07 - Elements !*/
/*  HEADER STARTS  */
.header-area {
  position: relative;
  background: linear-gradient(to bottom, #cfd6ff, #e1e7ff, #f8fbff);
}
.header-area:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: url(../images/shape-a.svg);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  background-position: 100% 40%;
}
.header-area-s2 {
  position: relative;
}
.header-area-s2:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: url(../images/shape-e.svg), url(../images/shape-k.svg),
    linear-gradient(to bottom, #7585ff, #8a98ff, #b1bbff);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 100% 100%;
  z-index: 0;
}
.header-area-s3 {
  position: relative;
  padding-bottom: 80px;
  overflow: hidden;
}
.header-area-s3:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: url(../images/shape-i.svg),
    linear-gradient(to right, #d184f3 0%, #3b36be 100%);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 100% 100%;
  z-index: 0;
}
.header-content {
  padding-top: 10px;
}
.header-text {
  padding-top: 10px;
}
.header-text-lg {
  padding-top: 50px;
}
.header-text-lg h1 {
  font-size: 2em;
  font-weight: bold;
  line-height: 1.2;
}
.header-text-s2 {
  padding-bottom: 20px;
}
.header-text-s2 h1 {
  color: #fff;
  font-size: 1.5em;
  line-height: 1.375;
}
.header-text-s2 p {
  color: #fff;
  padding: 5px 0;
}
.header-text-s3 {
  padding-top: 20px;
  color: #fff;
}
.header-text-s3 h1 {
  line-height: 1.125;
  color: #fff;
}
.header-img {
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}
.header-img-s3 {
  max-width: 200px;
}
.header-img-s4 {
  padding-top: 20px;
}

.footer-img {
  max-width: 50%;
}

@media all and (max-width: 480px) {
  .navbar-brand img {
    max-width: 45%;
  }

  .footer-img {
    max-width: 25%;
  }

  .header-text-lg h1 {
    text-align: center;
  }

  h2.sec-title-xxl {
    text-align: center;
  }
}

@media all and (min-width: 480px) {
  .header-text {
    padding-top: 50px;
  }
  .header-text p {
    padding: 10px 0 20px 0;
  }
  .header-text-lg h1 {
    font-size: 2.5em;
  }
  .header-text-s2 h1 {
    font-size: 2.5em;
  }
  .header-img {
    max-width: 350px;
  }
}
@media all and (min-width: 576px) {
  .header-text-s3 {
    padding-top: 50px;
  }
  .header-text-s3 .lead {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .header-text-s3 .header-btn {
    padding-top: 30px;
  }
  .header-img-s3 {
    max-width: 400px;
  }
}
@media all and (min-width: 768px) {
  .header-area-s3 {
    padding-bottom: 100px;
  }
  .header-text-lg h1 {
    font-size: 3em;
  }
  .header-text-s2 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .header-text-s2 p {
    padding: 10px 15px;
  }
  .header-text-s3 {
    padding-top: 20px;
  }
  .header-text-s3 .lead {
    padding-top: 10px;
  }
  .header-text-s3 .header-content-s2 h1 {
    font-size: 2.5em;
  }
  .header-img {
    max-width: 450px;
  }
  .header-img-s2 {
    max-width: 300px;
  }
  .header-img-s3 {
    max-width: 450px;
  }
}
@media all and (min-width: 992px) {
  .header-content {
    padding-top: 150px;
  }
  .header-content-s2 {
    padding-top: 100px;
  }
  .header-text-lg p {
    padding-top: 10px;
  }
  .header-text-s2 {
    padding-bottom: 50px;
  }
  .header-img-s3 {
    max-width: 100%;
    margin-right: -140px;
  }
  .header-img-s4 {
    margin-top: -90px;
    max-width: 550px;
    padding-left: 50px;
  }
}
@media all and (min-width: 1400px) {
  .header-content-s2 {
    padding-top: 150px;
  }
  .header-text-s2 {
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 75px;
  }
  .header-text-s2 p {
    padding: 15px 25px;
  }
  .header-text-s3 {
    padding-top: 50px;
  }
  .header-text-s3 .lead {
    padding-top: 20px;
  }
  .header-text-s3 .header-content-s2 h1 {
    font-size: 3em;
  }
  .header-img {
    max-width: 100%;
  }
  .header-img-s2 {
    max-width: 100%;
  }
  .header-img-s4 {
    max-width: 100%;
  }
}
@media all and (min-width: 2000px) {
  .header-area-s3 {
    padding-bottom: 250px;
  }
}
@media all and (min-width: 3000px) {
  .header-area-s3 {
    padding-bottom: 400px;
  }
}
.header-area-s4 {
  padding-bottom: 50px;
}
.header-area-s4 .header-text-s3 {
  padding-top: 50px;
  margin-top: -100px;
}
.header-area-s4 .header-text-s3 h5 {
  display: inline-block;
  font-size: 1.125em;
  line-height: 2em;
  color: #fff;
}
.header-area-s4 .header-text-s3 p {
  padding: 5px 0 0 0;
}
.header-area-s4 .about-header-img {
  padding-top: 20px;
  max-width: 200px;
}

@media all and (min-width: 576px) {
  .header-area-s4 .about-header-img {
    max-width: 300px;
  }
}
@media all and (min-width: 768px) {
  .header-area-s4 .about-header-img {
    max-width: 400px;
  }
  .header-area-s4 .header-content {
    padding-bottom: 50px;
  }
}
@media all and (min-width: 992px) {
  .header-area-s4 .header-text-s3 {
    padding-top: 120px;
    margin-top: 0;
  }
  .header-area-s4 .header-text-s3 h1 {
    padding-right: 70px;
  }
  .header-area-s4 .header-text-s3 p {
    padding: 20px 0 0 0;
  }
  .header-area-s4 .header-content {
    padding-bottom: 0;
  }
}
@media all and (min-width: 1400px) {
  .about-header-img {
    max-width: 100%;
  }
}
/*  HEADER ENDS  */
/*  WHY THIS STARTS  */
.why-box {
  padding: 10px 8px 10px 8px;
  background-color: #ffffff;
  box-shadow: 0 0 90px rgba(14, 0, 137, 0.06);
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
.why-box .why-img {
  width: 90px;
  height: auto;
  padding-bottom: 10px;
  margin: 0 auto;
}
.why-box h2 {
  font-size: 1.03em;
  line-height: 1.8;
  font-weight: 600;
}
.why-box-sm {
  box-shadow: none;
  position: relative;
  transition: 0.3s;
}
.why-box-sm .why-img {
  padding-bottom: 20px;
}
.why-box-sm:hover {
  box-shadow: 0 0 90px rgba(14, 0, 137, 0.06);
  z-index: 4;
  transform: scale(1.01);
  position: relative;
  border: none;
  background: #fff;
}
.why-this-content {
  padding: 5px 0;
  text-align: center;
}
.why-this-content h2 {
  font-weight: bold;
  font-size: 1em;
  line-height: 2;
}

@media all and (min-width: 480px) {
  .why-box {
    padding: 30px 20px 30px 20px;
  }
  .why-box h2 {
    font-size: 1.25em;
  }
  .why-this-content {
    padding: 10px 0;
  }
  .why-this-content h2 {
    font-size: 1.125em;
  }
}
@media all and (min-width: 768px) {
  .why-box {
    padding: 85px 35px 85px 35px;
    max-width: 100%;
  }
  .why-box .why-img {
    width: 130px;
    padding-bottom: 30px;
  }
  .why-box-sm {
    padding: 30px 40px 40px 40px;
  }
  .why-box-sm .why-img {
    width: 100px;
    padding-bottom: 20px;
  }
  .why-this-content {
    padding: 20px 0;
    text-align: left;
  }
}
@media all and (min-width: 992px) {
  .why-box {
    margin-bottom: 0;
  }
  .why-this-content {
    padding: 0;
  }
}
/*  HOSTA-WHY STARTS  */
.why-xiom-s4 {
  position: relative;
}
.why-xiom-s4 h2,
.why-xiom-s4 p {
  color: #fff;
}
.why-xiom-s4:before {
  content: '';
  position: absolute;
  background: #1a0c52;
  height: 100%;
  width: 110%;
  top: -2%;
  left: -5%;
  transform: rotate(-2deg);
  z-index: 0;
}
.why-xiom-img {
  margin-bottom: 50px;
}
.why-xiom-item {
  margin: 5px 0;
  text-align: center;
}
.why-xiom-item h2 {
  position: relative;
  top: 3px;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1.25;
  padding-top: 10px;
  margin-bottom: 0;
}
.why-xiom-item p {
  padding-top: 10px;
}
.why-xiom-item-s2 {
  margin-bottom: 20px;
}
.why-xiom-item-s2 .why-xiom-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40px;
  height: 50px;
  margin-bottom: 0;
}
.why-xiom-item-s2 h2 {
  display: block;
  font-weight: 600;
  font-size: 1.25em;
  line-height: 1.5;
}
.why-xiom-item-s2 p {
  padding-left: 0;
  padding-right: 0;
}
.why-xiom-icon {
  display: block;
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border-radius: 3px;
  padding: 5px;
  box-shadow: 0px 14px 35px 0px rgba(0, 0, 0, 0.05);
}
.why-xiom-icon-s2 {
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.why-xiom-btn {
  padding-top: 10px;
}

@media all and (min-width: 576px) {
  .why-xiom-item {
    margin: 25px 0;
  }
  .why-xiom-item h2 {
    font-size: 1.5em;
  }
}
@media all and (min-width: 768px) {
  .why-xiom-item {
    text-align: left;
  }
  .why-xiom-item h2 {
    padding-left: 15px;
    padding-top: 0;
  }
  .why-xiom-item-s2 {
    margin: 0;
  }
  .why-xiom-item-s2 .why-xiom-icon {
    margin-bottom: 35px;
  }
  .why-xiom-icon {
    display: inline-block;
  }
  .why-xiom-icon-s2 {
    margin-bottom: 30px;
  }
  .why-xiom-btn {
    padding-top: 20px;
  }
}
@media all and (min-width: 992px) {
  .why-xiom-img {
    margin-bottom: 0;
  }
  .why-xiom-item-s2 p {
    padding-left: 45px;
    padding-right: 45px;
  }
}
/*  WHY THIS ENDS  */
/*  ABOUT US STARTS  */
.about-us-heading {
  padding-bottom: 20px;
}
.about-us-heading .lead {
  padding: 10px 0 5px 0;
  margin-bottom: 0;
}
.about-us-content h2 {
  font-size: 1.25em;
  line-height: 1.5;
}

@media all and (min-width: 768px) {
  .about-us-heading {
    padding-bottom: 40px;
  }
}
/*  ABOUT US ENDS  */
/*  ADDRESS STARTS  */
.address-box {
  padding: 20px 0 25px 0;
  background-color: #ffffff;
  box-shadow: 0 0 90px rgba(14, 0, 137, 0.06);
  margin-bottom: 30px;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}
.address-box h2 {
  font-size: 1.03em;
  font-weight: 600;
  line-height: 1;
}
.address-box-s2 {
  margin-bottom: 30px;
}
.address-box-s2 h2 {
  font-size: 1.5em;
  line-height: 1;
  color: #3d486a;
  padding-bottom: 10px;
  margin: 0;
}
.address-list {
  padding-top: 10px;
}

@media all and (min-width: 480px) {
  .address-box {
    max-width: 350px;
  }
  .address-box h2 {
    font-size: 1.5em;
  }
}
@media all and (min-width: 576px) {
  .address-box {
    padding: 35px 0 40px 0;
    max-width: 450px;
  }
  .address-box-s2 h2 {
    padding-bottom: 15px;
  }
}
@media all and (min-width: 768px) {
  .address-box {
    padding: 50px 0 55px 0;
    max-width: 100%;
  }
  .address-box-s2 {
    margin-bottom: 0;
  }
  .address-box-s2 h2 {
    padding-bottom: 20px;
  }
}
@media all and (min-width: 992px) {
  .address-box {
    margin-bottom: 0;
  }
}
/*  ADDRESS ENDS  */
/*  TEAM STARTS  */
.team {
  position: relative;
}
.team:before {
  content: '';
  position: absolute;
  top: 0;
  left: -5px;
  height: 100%;
  width: 102%;
  background: url(../images/shape-c.svg),
    linear-gradient(to bottom, #e3e8ff, #f2f6ff, #f8fbff);
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 0;
}
.team-content {
  margin-top: 30px;
}
.team-photo {
  width: 110px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.team-photo img {
  border-radius: 50%;
  border: 8px solid #ccd8ff;
}
.team-photo-s2 {
  margin-left: auto;
  margin-right: auto;
  max-width: 200px;
}
.team-photo-s2 img {
  border-radius: 5px;
}
.team-info {
  margin-top: 10px;
  margin-bottom: 10px;
}
.team-info h2 {
  font-size: 1em;
  font-weight: 600;
}
.team-info h2 span {
  color: #6f7c8a;
  font-size: 1em;
  font-weight: normal;
  display: block;
}
.team-info-s2 h2 {
  font-family: 'Poppins';
  font-size: 1.125em;
  color: #3d486a;
  padding-top: 5px;
}
.team-info-s2 span {
  font-family: 'Lato';
  line-height: 1.5;
}

@media all and (min-width: 480px) {
  .team-photo {
    width: 120px;
  }
  .team-info h2 {
    font-size: 1.125em;
  }
  .team-info h2 span {
    font-size: 0.875em;
  }
}
@media all and (min-width: 576px) {
  .team-photo-s2 {
    max-width: 250px;
  }
  .team-info-s2 h2 {
    padding-top: 15px;
  }
}
@media all and (min-width: 768px) {
  .team-photo-s2 {
    max-width: 100%;
  }
  .team-info-s2 h2 {
    padding-top: 25px;
  }
}
@media all and (min-width: 992px) {
  .team-info h2 span {
    padding-top: 5px;
  }
}
/*  TEAM ENDS  */
/*  CONFIG STARTS */
.config {
  position: relative;
  background: #f8fbff;
}
.config-shape {
  position: absolute;
  width: 100%;
  height: 100%;
}
.config-shape svg {
  height: 100%;
  width: auto;
  background: linear-gradient(to right, #e3e8ff, #f2f6ff);
}
.config-shape path {
  fill: #f8fbff;
}
.config-alt {
  background: #fff;
}
.config-alt .config-shape path {
  fill: #fff;
}

@media all and (min-width: 992px) {
  .config-img {
    margin-left: -50px;
  }
  .config-img.nomr {
    margin-left: 0;
  }
}
/*  CONFIG ENDS */
/*  TOOLS STARTS  */
.tools-s3 {
  position: relative;
  color: #fff;
}
.tools-s3 .text-block-2 h2 {
  color: #fff;
}
.tools-s3:before {
  content: '';
  position: absolute;
  background: #1a0c52;
  height: 100%;
  width: 110%;
  top: -2%;
  left: -5%;
  transform: rotate(-2deg);
  z-index: 0;
}
.tools-icon-s3 {
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(to right, #674ece, #b476e9);
  border-radius: 10px;
  position: relative;
}
.tools-icon-s3 img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.tools-text-s3 {
  padding-top: 20px;
  color: #fff;
}
.tools-text-s3 h2 {
  color: #fff;
  padding-bottom: 10px;
}
.tools-img-s3 {
  padding-bottom: 50px;
}

@media all and (min-width: 576px) {
  .tools-icon-s3 {
    width: 60px;
    height: 60px;
  }
  .tools-text-s3 {
    padding-top: 30px;
  }
  .tools-text-s3 h2 {
    padding-bottom: 20px;
  }
}
@media all and (min-width: 768px) {
  .tools .text-block h2 {
    padding-left: 5px;
  }
  .tools-icon-s3 {
    margin-left: auto;
    margin-right: 0;
  }
  .tools-img-s3 {
    padding-bottom: 0;
  }
}
/*  TOOLS ENDS  */
/*  QUICK ACCESS STARTS  */
.quick-access-icon {
  width: 40px;
  height: 40px;
  background: linear-gradient(to right, #674ece, #b476e9);
  border-radius: 10px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.quick-access-icon img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.quick-access-text {
  padding-top: 20px;
}
.quick-access-text h2 {
  padding-bottom: 10px;
}
.quick-access-img {
  padding-bottom: 50px;
}

@media all and (min-width: 576px) {
  .quick-access-icon {
    width: 60px;
    height: 60px;
  }
  .quick-access-text {
    padding-top: 30px;
  }
  .quick-access-text h2 {
    padding-bottom: 20px;
  }
}
@media all and (min-width: 768px) {
  .quick-access-icon {
    margin-left: 0;
  }
  .quick-access-img {
    padding-bottom: 0;
  }
}
/*  QUICK ACCESS ENDS */
/*  FEATURE STARTS  */
.feature {
  position: relative;
}
.feature:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 102%;
  background: url(../images/shape-c.svg),
    linear-gradient(to bottom, #e3e8ff, #f2f6ff, #f8fbff);
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 0;
}
.feature-s2:before {
  background: url(../images/shape-g.svg),
    linear-gradient(to bottom, #f1f2ff, #fafbff, #fff);
  background-repeat: no-repeat;
  background-size: 100%;
}
.feature-item {
  margin: 10px 0;
  text-align: center;
}
.feature-item h2 {
  font-weight: 600;
  font-size: 1em;
  line-height: 2;
  margin: 0;
}
.feature-item img {
  height: 60px;
  width: auto;
  margin-bottom: 5px;
}
.feature-item-s3 {
  margin-bottom: 30px;
  padding: 20px 10px 20px 10px;
  box-shadow: 0px 0px 60px 0px rgba(76, 63, 196, 0.06);
  background-color: #fff;
  display: block;
  height: calc(100% - 30px);
}
.feature-item-s4 {
  margin-bottom: 30px;
}
.feature-text-s3 {
  text-align: center;
  padding-top: 20px;
}
.feature-text-s3 h2 {
  font-size: 1.25em;
  line-height: 1.5;
}
.feature-text-s4 {
  padding-top: 20px;
}
.feature-text-s4 h2 {
  font-size: 1.25em;
  line-height: 1.5;
}
.feature-icon-s3 {
  width: 60px;
  height: 60px;
  margin: 0 auto;
}
.feature-icon-s4 {
  width: 65px;
  height: 65px;
  margin: 0 auto;
}

@media all and (min-width: 768px) {
  .feature-item {
    margin: 20px 0;
    text-align: left;
  }
  .feature-item-s3 {
    display: flex;
    padding: 30px 40px 40px 30px;
  }
  .feature-text-s3 {
    text-align: left;
    padding-left: 20px;
    padding-top: 8px;
  }
  .feature-icon-s3 {
    flex-shrink: 0;
  }
  .feature-icon-s4 {
    width: 85px;
    height: 85px;
  }
}
@media all and (min-width: 992px) {
  .feature-item h2 {
    font-size: 1.125em;
  }
  .feature-item img {
    margin-bottom: 30px;
  }
}
/*  FEATURE ENDS  */
/*  TESTIMONIAL STARTS  */
.testimonial-box {
  padding: 30px 25px 40px 25px;
  color: #919ca7;
  background-color: #ffffff;
  box-shadow: 0 0 90px rgba(14, 0, 137, 0.06);
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}
.testimonial-client {
  position: relative;
  top: -35px;
}
.testimonial-client-img {
  width: 60px;
  margin: 0 auto;
}
.testimonial-client-img img {
  border-radius: 50%;
}
.testimonial-client-details {
  padding-top: 10px;
}
.testimonial-client-details h6 {
  font-size: 1em;
  font-weight: 600;
  display: block;
}
.testimonial-client-details span {
  font-weight: normal;
  font-size: 0.875em;
  display: block;
}
.testimonial-client-s3 {
  width: 3000px;
  height: 400px;
  background: linear-gradient(to right, #453bc1 0%, #d385f4 20%, #d385f4 100%);
  border-radius: 50px 0 0 50px;
  transform: rotate(-2deg);
  position: relative;
}
.testimonial-client-photo-a-s3 {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 150px;
  left: 10px;
}
.testimonial-client-photo-a-s3 img {
  border-radius: 50%;
}
.testimonial-client-photo-b-s3 {
  width: 45px;
  height: 45px;
  position: absolute;
  top: 70px;
  left: 120px;
}
.testimonial-client-photo-b-s3 img {
  border-radius: 50%;
}
.testimonial-client-photo-c-s3 {
  width: 45px;
  height: 45px;
  position: absolute;
  bottom: 75px;
  left: 120px;
}
.testimonial-client-photo-c-s3 img {
  border-radius: 50%;
}
.testimonial-client-photo-d-s3 {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 180px;
  left: 200px;
}
.testimonial-client-photo-d-s3 img {
  border-radius: 50%;
}
.testimonial-text-s3 {
  position: relative;
}
.testimonial-text-s3 span {
  position: absolute;
  top: 10px;
  font-size: 9.375em;
  line-height: 0.04;
  color: #3931ec;
  font-weight: normal;
}
.testimonial-text-s3 blockquote {
  font-size: 1.2em;
  line-height: 1.6;
}
.testimonial-text-s3 p {
  color: #b7bdc5;
}

@media all and (min-width: 400px) {
  .testimonial-text-s3 {
    margin-top: 120px;
  }
  .testimonial-text-s3 blockquote {
    font-size: 1.8em;
  }
  .testimonial-client-photo-b-s3 {
    left: 180px;
  }
  .testimonial-client-photo-c-s3 {
    left: 180px;
  }
  .testimonial-client-photo-d-s3 {
    left: 300px;
  }
}
@media all and (min-width: 480px) {
  .testimonial-client-s3 {
    height: 550px;
  }
  .testimonial-client-photo-a-s3 {
    width: 150px;
    height: 150px;
    top: 150px;
  }
  .testimonial-client-photo-b-s3 {
    width: 60px;
    height: 60px;
    left: 250px;
  }
  .testimonial-client-photo-c-s3 {
    width: 60px;
    height: 60px;
    left: 250px;
    bottom: 140px;
  }
  .testimonial-client-photo-d-s3 {
    width: 80px;
    height: 80px;
    left: 365px;
  }
}
@media all and (min-width: 576px) {
  .testimonial-client-photo-a-s3 {
    width: 180px;
    height: 180px;
    left: 50px;
  }
  .testimonial-client-photo-b-s3 {
    width: 70px;
    height: 70px;
    left: 280px;
  }
  .testimonial-client-photo-c-s3 {
    width: 70px;
    height: 70px;
    left: 280px;
  }
  .testimonial-client-photo-d-s3 {
    width: 90px;
    height: 90px;
    left: 400px;
  }
}
@media all and (min-width: 620px) {
  .testimonial-client-photo-a-s3 {
    width: 200px;
    height: 200px;
    left: 100px;
  }
  .testimonial-client-photo-b-s3 {
    width: 70px;
    height: 70px;
    left: 340px;
  }
  .testimonial-client-photo-c-s3 {
    width: 70px;
    height: 70px;
    left: 340px;
  }
  .testimonial-client-photo-d-s3 {
    width: 90px;
    height: 90px;
    left: 450px;
  }
}
@media all and (min-width: 768px) {
  .testimonial-box {
    padding: 40px 35px 50px 35px;
    max-width: 100%;
  }
  .testimonial-client-details h6 {
    font-size: 1.125em;
  }
  .testimonial-client-photo-a-s3 {
    top: 120px;
    left: 5px;
  }
  .testimonial-client-photo-b-s3 {
    top: 40px;
    left: 300px;
  }
  .testimonial-client-photo-c-s3 {
    bottom: 100px;
    left: 300px;
  }
  .testimonial-client-photo-d-s3 {
    top: 180px;
    left: 500px;
  }
}
@media all and (min-width: 992px) {
  .testimonial-client-photo-a-s3 {
    top: 120px;
    left: -120px;
  }
  .testimonial-client-photo-b-s3 {
    top: 70px;
    left: 150px;
  }
  .testimonial-client-photo-c-s3 {
    bottom: 100px;
    left: 150px;
  }
  .testimonial-client-photo-d-s3 {
    top: 180px;
    left: 250px;
  }
}
@media all and (min-width: 1200px) {
  .testimonial-client-photo-a-s3 {
    width: 270px;
    height: 270px;
  }
  .testimonial-client-photo-b-s3 {
    width: 80px;
    height: 80px;
    left: 250px;
  }
  .testimonial-client-photo-c-s3 {
    width: 80px;
    height: 80px;
    left: 250px;
  }
  .testimonial-client-photo-d-s3 {
    width: 110px;
    height: 110px;
    left: 400px;
  }
}
/*  TESTIMONIAL ENDS  */
/*  CTA/SUBSCRIBER STARTS  */
.cta {
  position: relative;
  margin-bottom: -200px;
  z-index: 6;
}
.cta-box {
  background-color: #fff;
  padding: 40px 30px;
  text-align: center;
  box-shadow: 0 0 90px rgba(14, 0, 137, 0.06);
}
.cta-box .input-heading {
  font-size: 1.5em;
  font-weight: 600;
  line-height: 0.75;
  margin-bottom: 20px;
}
.cta-box h2 {
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.6;
  padding-bottom: 20px;
}

@media all and (min-width: 480px) {
  .cta-box .input-heading {
    font-size: 2.5em;
  }
  .cta-box h2 {
    font-size: 1.5em;
  }
}
@media all and (min-width: 576px) {
  .cta-box {
    padding: 60px 50px 60px 50px;
  }
  .cta-box .input-heading {
    font-size: 3em;
  }
  .cta-box h2 {
    font-size: 1.8755em;
  }
}
@media all and (min-width: 768px) {
  .cta-box {
    padding: 70px 30px;
    text-align: left;
  }
  .cta-box h2 {
    padding-bottom: 0;
  }
}
@media all and (min-width: 992px) {
  .cta-box {
    padding: 110px 100px;
  }
  .cta-btn {
    text-align: right;
  }
}
.subscriber {
  position: relative;
  /* margin-bottom: -200px; */
  z-index: 6;
}
.subscriber-box {
  padding: 60px 0 60px 0;
}
.subscriber-box h2 {
  padding-bottom: 0;
}
.subscriber-box .subscriber-text {
  font-size: 1.3em;
  font-weight: 600;
  line-height: 1.6;
  padding: 0 20px;
}
.subscriber-box .subscriber-text-xl {
  font-size: 1.5em;
  font-weight: 600;
  line-height: 1;
}

@media all and (min-width: 576px) {
  .subscriber-box .subscriber-text {
    font-size: 1.875em;
  }
  .subscriber-box .subscriber-text-xl {
    font-size: 2.77em;
  }
}
@media all and (min-width: 768px) {
  .subscriber-box .subscriber-text {
    padding: 0;
  }
}
@media all and (min-width: 992px) {
  .subscriber-box {
    padding: 100px 0 100px 0;
  }
}
.input-contact {
  width: 100%;
  border: none;
  border-bottom: 1px solid #e2e7ed;
  color: #b5c3d2;
  font-size: 1em;
  line-height: 1.5;
  padding-bottom: 10px;
}
.input-field {
  margin: 10px 0 20px 0;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.input-field-s2 {
  margin-top: 20px;
}
.input-line {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ff5e8d;
  color: #b5c3d2;
  font-size: 1em;
  line-height: 1.6;
  padding-bottom: 5px;
}

.subscriber-btn {
  margin-top: 30px;
}

.contact-info {
  padding: 60px 30px 50px 30px;
}

@media all and (min-width: 480px) {
  .input-field {
    margin: 30px 0 20px 0;
    margin-left: auto;
    margin-right: auto;
  }
  .input-line {
    font-size: 1.875em;
    padding-bottom: 10px;
  }
}
@media all and (min-width: 576px) {
  .input-field {
    margin: 50px 0 40px 0;
    margin-left: auto;
    margin-right: auto;
  }
  .input-field-s2 {
    margin-top: 40px;
  }
  .subscriber-btn {
    margin-top: 60px;
  }
  .contact-info {
    padding: 80px 50px 70px 50px;
  }
}
@media all and (min-width: 768px) {
  .input-field {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
.map {
  min-height: 350px;
  margin-top: 50px;
  border: 8px solid #fff;
  box-shadow: 0 0 90px rgba(14, 0, 137, 0.06);
}

@media all and (min-width: 992px) {
  .map {
    width: 100%;
    height: 100%;
    margin-top: 0;
  }
}
/*  HOSTA CTA STARTS  */
.cta-s2 {
  position: relative;
}
.cta-s2:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: url(../images/shape-j.svg),
    linear-gradient(to right, #3b36be 0%, #d184f3 100%);
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 0;
}
.cta-content-s2 {
  padding-top: 50px;
}
.cta-content-s2 h2 {
  color: #fff;
  line-height: 0.83;
  padding-bottom: 35px;
}

.subscriber-s2 {
  margin-bottom: 0;
}
.subscriber-s2 h2 {
  font-weight: 600;
  font-size: 3em;
  line-height: 0.75;
  color: #505a77;
}

.subscribe-results.alert {
  margin-top: 30px;
}

.form-results.alert {
  margin-top: 50px;
}

label.error {
  font-size: 13px;
}

.subscriber-s3 {
  position: relative;
}
.subscriber-s3:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: url(../images/shape-j.svg),
    linear-gradient(to right, #3b36be 0%, #d184f3 100%);
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 0;
}
.subscriber-content-s3 {
  padding-top: 80px;
}
.subscriber-content-s3 h2 {
  color: #fff;
  padding-bottom: 50px;
}
.subscriber-content-s3 .subscribe-results.alert {
  margin-top: 20px;
}
.subscriber-content-s3 label.error {
  margin-top: 10px;
  color: #fff;
}

.input-s3 {
  position: relative;
}
.input-line-s3 {
  width: 100%;
  border: none;
  color: #a5adbb;
  font-family: 'Poppins';
  font-size: 1.125em;
  line-height: 1.87;
  padding: 20px 0;
  border-radius: 50px;
  text-align: center;
}
.input-icon-s3 {
  position: absolute;
  top: 22px;
  left: 30px;
}
.input-icon-s3 i {
  color: #4a5b7a;
  font-size: 1.125em;
}
.input-btn-s3 {
  display: block;
  padding-top: 30px;
}

.input-s3 input[type='email'] {
  padding-left: 40px;
}

@media (min-width: 576px) {
  .input-line-s3 {
    padding: 35px 0;
    text-align: left;
  }
  .input-icon-s3 {
    left: 45px;
    top: 39px;
  }
  .input-btn-s3 {
    position: absolute;
    right: 30px;
    top: 28px;
    padding-top: 0;
  }
  .input-s3 input[type='email'] {
    padding-left: 80px;
  }
}
/*  CTA/SUBSCRIBER ENDS  */
/*  FOOTER STARTS  */
.footer-area {
  position: relative;
}
.footer-area:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url(../images/shape-d.svg),
    linear-gradient(to bottom, #e3e8ff, #f2f6ff);
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 0;
}
.footer-area-s2:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url(../images/shape-h.svg),
    linear-gradient(to bottom, #e3e8ff, #f2f6ff);
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 0;
}
.footer-content {
  position: relative;
  padding-top: 240px;
  padding-bottom: 75px;
}
.footer-widget {
  margin-top: 20px;
  text-align: center;
}
.footer-widget a {
  color: #6f7c8a;
}
.footer-widget a:hover {
  color: #3498db;
}
.footer-widget-s3 {
  text-align: center;
}
.footer-widget-s3 a {
  color: #adb4bb;
}
.footer-widget-s3 a:hover {
  color: #3498db;
}
.footer-widget-s3 .social-icon {
  background-color: #4a5b7a;
}
.footer-widget-s3 .social-icon a {
  color: #fff;
}
.footer-widget-s3 .social-icon:hover {
  background-color: #c8cfe7;
}
.footer-widget-s3 .social-icon:hover a {
  color: #4a5b7a;
}
.footer-widget-header-s3 {
  font-size: 1.125em;
}
.footer-list {
  padding-left: 0;
  margin: 0;
}
.footer-text {
  padding: 30px 0 40px 0;
  font-size: 0.875em;
  font-weight: normal;
  line-height: 1.5;
  border-top: 1px solid #dadee3;
}
.footer-text-s3 {
  font-family: 'Hind Madurai';
  font-size: 0.87em;
  line-height: 1.5;
  color: #adb4bb;
  padding-top: 12px;
}
.footer-text-s3 span {
  font-weight: 600;
  color: #adb4bb;
}

.social {
  padding-top: 15px;
}

.social-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #c8cfe7;
  display: inline-block;
  position: relative;
  transition: all 0.5s ease;
}
.social-icon a {
  color: #424c6d;
  display: block;
}
.social-icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.social-icon:hover {
  background-color: #424c6d;
}
.social-icon:hover a {
  color: #c8cfe7;
}

.social-icon + .social-icon {
  margin-left: 5px;
}

.up-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #fff;
  background-color: #ff5e8d;
  display: inline-block;
  position: absolute;
  bottom: 4%;
  right: 0;
  box-shadow: 0px 15px 40px 0px rgba(255, 21, 89, 0.4);
  cursor: pointer;
  transition: all 0.3s;
}
.up-icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.up-icon:hover {
  background-color: #7c98e7;
  box-shadow: 0px 15px 40px 0px rgba(124, 152, 231, 0.3);
  transition: all 0.3s;
}

@media all and (min-width: 576px) {
  .footer-content {
    padding-top: 280px;
  }
  .footer-text {
    padding: 40px 0 45px 0;
  }
}
@media all and (min-width: 768px) {
  .footer-content {
    padding-top: 290px;
  }
  .footer-widget {
    margin-top: 0;
    text-align: left;
  }
  .footer-widget-s3 {
    text-align: left;
  }
  .up-icon {
    bottom: 2%;
  }
}
@media all and (min-width: 992px) {
  .footer-widget-s3 {
    padding-left: 80px;
  }
  .footer-widget-first {
    padding-left: 0;
  }
  .up-icon {
    bottom: 4%;
  }
}
/*  FOOTER ENDS  */
/*  LOGIN STARTS  */
.login {
  min-height: 100vh;
  padding: 50px 0;
  background-color: #f8fbff;
}
.login .input-field {
  margin: 0;
  width: 100%;
}
.login .input-field-s2 {
  margin: 20px 0 10px 0;
}
.login-content {
  background-color: #fff;
  padding: 30px 0 15px 0;
  box-shadow: 0 0 90px rgba(14, 0, 137, 0.06);
  border-radius: 10px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.login-content h2 {
  font-size: 2em;
  font-weight: bold;
  line-height: 1.5;
}
.login-content-s2 {
  box-shadow: 0 0 90px rgba(14, 0, 137, 0.06);
  padding: 0 20px 0 20px;
}
.login-title {
  padding-bottom: 15px;
}
.login-text {
  padding-top: 10px;
}
.login-text p {
  font-size: 1em;
  line-height: 4.5;
}
.login-text span {
  padding-left: 5px;
}
.login-text a:hover {
  color: #2980b9;
}
.login-btn {
  text-align: left;
  padding-left: 15%;
  padding-top: 10px;
}

.ball-lg {
  position: absolute;
  top: 20%;
  left: -120px;
  width: 80px;
  height: 80px;
}
.ball-sm {
  position: absolute;
  top: 30%;
  right: -70px;
  width: 50px;
  height: 50px;
}

.zigzag-shape {
  position: absolute;
  bottom: -30%;
  left: 30%;
  width: 200px;
  height: 200px;
  z-index: -1;
}

.input-login {
  width: 70%;
  border: none;
  font-size: 0.875em;
  border-bottom: 1px solid #e2e7ed;
}

.check-box {
  padding-top: 15px;
}

.check-box-2 {
  padding-left: 15%;
}

.check-text {
  padding-left: 5px;
  font-family: 'Lato';
}

.checkbox-label {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 1px solid #bfc9d4;
  border-radius: 5px;
}
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-label input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-label .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #6f7c8a;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media all and (min-width: 576px) {
  .login .input-field-s2 {
    margin: 60px 0 20px 0;
  }
  .login-content {
    padding: 75px 0 15px 0;
    max-width: 100%;
  }
  .login-content h2 {
    font-size: 3em;
  }
  .login-content-s2 {
    padding: 0 50px 0 50px;
  }
  .login-title {
    padding-bottom: 20px;
  }
  .login-text {
    padding-top: 25px;
  }
  .input-login {
    font-size: 1em;
  }
}
@media all and (min-width: 768px) {
  .login-btn {
    padding-left: 0;
    padding-top: 0;
    text-align: center;
  }
}
@media all and (min-width: 992px) {
  .login-content-s2 {
    padding: 80px 0 60px 0;
  }
}
/*  HOSTA LOGIN STARTS  */
.login-s2 {
  overflow: hidden;
  padding: 50px 0;
  position: relative;
  background-color: #fff;
}
.login-s2:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 80%;
  width: 101%;
  background: url(../images/shape-i.svg),
    linear-gradient(to right, #d184f3 0%, #3b36be 100%);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 100% 100%;
  z-index: 0;
}
.login-s2 .input-login {
  width: 100%;
}
.login-s2 .check-text {
  font-family: 'Poppins';
}
.login-s2 .login-btn-s2 {
  padding-top: 10px;
}
.login-s2 .login-img {
  padding: 50px 0 20px 0;
}
.login-s2 .login-img img {
  max-height: 200px;
}

@media all and (min-width: 576px) {
  .login-s2 .check-box {
    padding-right: 0;
  }
}
@media all and (min-width: 768px) {
  .login-s2 .login-btn-s2 {
    text-align: right;
    padding-top: 0;
  }
}
@media all and (min-width: 992px) {
  .login-s2 {
    padding: 100px 0;
  }
  .login-s2 .login-title {
    padding-bottom: 60px;
  }
  .login-s2 .login-img {
    padding: 0;
  }
  .login-s2 .login-img img {
    max-height: 500px;
  }
}
/*  LOGIN ENDS  */
/*  PRICING STARTS  */
.pricing-box {
  padding: 0 0 40px 0;
  background-color: #ffffff;
  box-shadow: 0 0 90px rgba(14, 0, 137, 0.06);
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  transition: all 0.5s;
}
.pricing-box sup {
  font-size: 0.3em;
  top: -40px;
  left: 15px;
}
.pricing-box:hover {
  transform: translateY(-8px);
  z-index: 8;
}
.pricing-box.active {
  background-color: #7c98e7;
  transition: all 0.5s;
}
.pricing-box.active .btn-price {
  background-color: #ff5e8d;
  color: #fff;
}
.pricing-box.active .btn-price:hover {
  box-shadow: none;
}
.pricing-box-s2 {
  padding: 35px 0 45px 0;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin-bottom: 30px;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.5s;
}
.pricing-box-s2 sup {
  font-size: 0.3em;
  top: -40px;
  left: 15px;
}
.pricing-box-s2:hover {
  transform: translateY(-8px);
}
.pricing-box-s2.active {
  background: linear-gradient(to right, #674ece, #b476e9);
  z-index: 5;
  border: none;
  position: relative;
  transform: scale(1.05);
  transition: all 0.3s;
}
.pricing-box-s2.active:hover {
  transform: translateY(-8px) scale(1.05);
}
.pricing-box-s4 {
  padding: 0 0 40px 0;
  background-color: #ffffff;
  box-shadow: 0px 0px 49px 0px rgba(134, 142, 167, 0.1);
  margin-bottom: 30px;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.5s;
}
.pricing-box-s4 sup {
  font-size: 0.3em;
  top: -40px;
  left: 10px;
}
.pricing-box-s4:hover {
  transform: translateY(-8px);
}
.pricing-title {
  width: 100%;
  border-bottom: 1px solid #e6edf7;
  font-size: 1.5em;
  line-height: 1;
  padding: 20px 0;
  font-weight: bold;
}
.active .pricing-title {
  color: #fff;
  border-bottom: 1px solid #e6edf7;
}
.pricing-title-s2 {
  font-size: 1.125em;
  line-height: 1.6;
  font-weight: normal;
}
.active .pricing-title-s2 {
  color: #fff;
}
.pricing-title-s4 {
  font-weight: normal;
  font-size: 1.125em;
  line-height: 1.6;
  color: #4a5b7a;
  padding-top: 30px;
}
.pricing-price {
  font-size: 3.5em;
  line-height: 1.5;
  display: block;
  color: #3d486a;
  padding-right: 25px;
}
.active .pricing-price {
  color: #fff;
}
.pricing-price-s2 {
  font-size: 4.5em;
  line-height: 1.2;
  display: block;
  color: #3d486a;
  padding-right: 25px;
}
.active .pricing-price-s2 {
  color: #fff;
}
.pricing-price-s4 {
  font-weight: normal;
  font-size: 4.5em;
  line-height: 1.5;
  display: block;
  color: #3d486a;
  padding-right: 25px;
}
.pricing-note {
  margin-top: -30px;
  color: #3d486a;
}
.active .pricing-note {
  color: #fff;
}
.pricing-note-s2 {
  font-family: 'Poppins';
  color: #8a95a1;
}
.active .pricing-note-s2 {
  color: #fff;
}
.pricing-note-s4 {
  font-family: 'Poppins';
  font-weight: normal;
  font-size: 1em;
  margin-top: -30px;
  color: #8a95a1;
  display: inherit;
  padding-top: 5px;
  padding-bottom: 20px;
}
.pricing-list {
  line-height: 2.25;
  padding: 10px 0;
}
.active .pricing-list {
  color: #fff;
}
.pricing-list-s2 {
  color: #8a95a1;
  padding: 20px 0 30px 0;
}
.active .pricing-list-s2 {
  color: #fff;
}
.pricing-list-s4 {
  font-family: 'Poppins';
  font-weight: normal;
  font-size: 1em;
  line-height: 1.875;
  color: #8a95a1;
  padding: 10px 0;
}

@media all and (min-width: 576px) {
  .pricing-box {
    max-width: 400px;
  }
  .pricing-box-s2 {
    max-width: 400px;
  }
  .pricing-box-s4 {
    max-width: 450px;
  }
  .pricing-title {
    font-size: 1.875em;
    padding: 40px 0;
  }
  .pricing-price {
    font-size: 4em;
  }
}
@media all and (min-width: 768px) {
  .pricing-box {
    max-width: 100%;
    padding: 0 0 75px 0;
  }
  .pricing-box-s2 {
    max-width: 100%;
  }
  .pricing-box-s4 {
    max-width: 100%;
  }
}
@media all and (min-width: 992px) {
  .pricing-box {
    margin-bottom: 0;
  }
  .pricing-box-s2 {
    margin-bottom: 0;
  }
  .pricing-box-s4 {
    margin-bottom: 0;
  }
}
/*  PRICING ENDS  */
/*  PARTNER STARTS  */
.partner h4 {
  color: #6f7c8a;
}
.partners4 {
  position: relative;
}
.partners4:before {
  content: '';
  position: absolute;
  background: linear-gradient(to bottom, #f6faff, #ffffff);
  transform: rotate(-2deg);
  height: 100%;
  width: 110%;
  top: -2%;
  left: -5%;
  z-index: 0;
}
.partner-list {
  padding-top: 10px;
  padding-left: 0;
  padding-right: 0;
}
.partner-list li {
  display: inline-block;
  margin: 5px 5px 5px 5px;
}
.partner-list img {
  width: 120px;
  height: auto;
}
.partner-info-s3 h2 {
  padding-bottom: 50px;
}
.partner-info-s3 li {
  display: inline-block;
  margin: 10px 10px;
}
.partner-img-s3 {
  width: 140px;
  height: 40px;
}

@media all and (min-width: 768px) {
  .partner {
    padding-bottom: 0;
  }
  .partner-list {
    padding-top: 30px;
  }
  .partner-list li {
    margin: 10px 15px 10px 15px;
  }
  .partner-list img {
    width: 100%;
  }
  .partner-info-s3 h2 {
    padding-bottom: 80px;
  }
  .partner-info-s3 li {
    margin: 20px 30px;
  }
  .partner-img-s3 {
    width: 160px;
    height: 60px;
  }
}
@media all and (min-width: 992px) {
  .partner-info-s3 li {
    margin: 20px 60px;
  }
}
/*  PARTNER ENDS  */
/*  FAQ STARTS  */
.faq-s2 {
  position: relative;
}
.faq-s2:before {
  content: '';
  position: absolute;
  background: #f6f9ff;
  height: 100%;
  width: 110%;
  top: -2%;
  left: -5%;
  transform: rotate(-2deg);
  z-index: 1;
}

.accordion-custom {
  margin-bottom: 10px;
  border: none;
}

.card-header {
  background: #ffffff;
  border: 0;
  padding: 3px 30px;
  position: relative;
}
.card-header h5 {
  font-size: 1.125em;
}
.card-header .btn-link {
  width: 100%;
  font-family: 'Hind Madurai';
  font-weight: 600;
  color: #4a5b7a;
  border: none;
}
.card-header .btn-link:focus {
  text-decoration: none;
}
.card-header .btn-link:hover {
  text-decoration: none;
}

.card-body {
  border-top: 1px solid #e7e9ee;
}
.card-body p {
  font-weight: normal;
  color: #9fa8b1;
  line-height: 2.25;
  padding: 0 25px;
}

.collapsed .faq-icon {
  transform: rotate(180deg);
}

.faq-icon {
  position: absolute;
  right: 5%;
}

/*  FAQ ENDS  */
.overlay-bar {
  position: absolute;
  background: linear-gradient(
    to right,
    rgba(202, 88, 252, 0.7) 0%,
    rgba(59, 54, 190, 0.7) 100%
  );
  transform: rotate(6deg);
  left: -10%;
  height: 650px;
  width: 120%;
  top: 100%;
  margin-top: -240px;
}
.overlay-bar:before {
  content: '';
  position: absolute;
  background: #fff;
  transform: rotate(-8deg);
  height: 650px;
  width: 110%;
  left: -5%;
  z-index: 0;
  top: 196px;
}
.overlay-bar-1 {
  position: absolute;
  background: linear-gradient(
    to right,
    rgba(59, 54, 190, 0.7) 0%,
    rgba(209, 132, 243, 0.7) 100%
  );
  left: -10%;
  height: 650px;
  width: 120%;
  top: 0;
  margin-top: -380px;
}
.overlay-bar-1:before {
  content: '';
  position: absolute;
  background: #fff;
  transform: rotate(-6deg);
  height: 650px;
  width: 110%;
  left: -5%;
  z-index: 0;
  top: 0;
  margin-top: -265px;
}
.overlay-bar-3 {
  top: 80%;
}

@media all and (min-width: 1400px) {
  .overlay-bar {
    top: 95%;
  }
  .overlay-bar:before {
    top: 230px;
  }
  .overlay-bar-3 {
    top: 75%;
  }
}
@media all and (min-width: 2000px) {
  .overlay-bar {
    top: 78%;
  }
  .overlay-bar:before {
    top: 385px;
  }
  .overlay-bar-2 {
    top: 68%;
  }
  .overlay-bar-3 {
    top: 60%;
  }
}
@media all and (min-width: 2500px) {
  .overlay-bar-3 {
    top: 62%;
  }
}
@media all and (min-width: 3000px) {
  .overlay-bar {
    top: 50%;
    height: 950px;
  }
  .overlay-bar:before {
    height: 850px;
    top: 630px;
  }
  .overlay-bar-2 {
    top: 28%;
  }
  .overlay-bar-3 {
    top: 55%;
  }
}
/** END */
